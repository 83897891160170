import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Desk Content for Programmers</p>
      
         {/* https://www.youtube.com/watch?v=xNRJwmlRBNU */}
         
        <script src="https://gumroad.com/js/gumroad.js"></script>
        <a class="gumroad-button" href="https://dc4p.gumroad.com/l/swift-complete-deck-of-cards-7fsd" data-gumroad-overlay-checkout="true">I want this!</a>

        <script src="https://gumroad.com/js/gumroad.js"></script>
<a class="gumroad-button" href="https://dc4p.gumroad.com/l/swift-extensions-cards" data-gumroad-overlay-checkout="true">Buy on</a>

      </header>
    </div>
  );
}

export default App;
